import React from 'react';
import { useLocation } from "react-router-dom";

import GetAllBlogPosts from '../../../../hooks/getAllBlogPosts';
import BlogCard from "../BlogCard/BlogCard";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import SwiperCore, { Navigation } from 'swiper';

import './BlogCardSwiper.css';
SwiperCore.use([Navigation]);

function BlogCardSwiper() {

    const { state } = useLocation();
    let { all_posts } = GetAllBlogPosts(state); 
    // all_posts.reverse();

    return (
        <div className="blog-card-swiper-container">
            <Swiper
                slidesPerView={1}
                navigation
                spaceBetween={10}
                pagination={{
                clickable: true,
                renderBullet: function(index, className) {
                    return '<span class="' + className + '"><img class="pagination-bullet" alt=""/></span>';
                }
                }}
                breakpoints={{
                    590: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    675: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    875: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1040: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1230: {
                        slidesPerView: 3.8,
                        spaceBetween: 20,
                    },
                    1900: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                }}
                modules={[Pagination]}
                className="blogCardSwiper"
            >
                {all_posts.map((post, index) => 
                    <SwiperSlide key={index}>
                        <BlogCard 
                            blogSlug={post.urlSlug}
                            blogTitle={post.title}
                            blogDate={post.date}
                            imageAltText={post.altText}
                        />
                    </SwiperSlide>
                )}
              </Swiper>
        </div>  
    )
}

export default BlogCardSwiper;