import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const widgetScriptSrc = "https://tally.so/widgets/embed.js";

function useTallyEmbed() {
    const location = useLocation();

    useEffect(() => {
        const loadTally = () => {
            if (window.Tally) {
                // console.log("Loading Tally embeds...");
                window.Tally.loadEmbeds();
            }
        };

        // Check if script already exists
        if (document.querySelector(`script[src="${widgetScriptSrc}"]`)) {
            // console.log("Tally script already exists, reloading embeds...");
            loadTally();
            return;
        }

        // Otherwise, create and append script
        const script = document.createElement("script");
        script.src = widgetScriptSrc;
        script.async = true;
        script.onload = () => {
            // console.log("Tally script loaded, initializing...");
            setTimeout(loadTally, 500); // Ensure embed is ready
        };

        document.body.appendChild(script);

        // Cleanup function to remove script on unmount
        return () => {
            script.remove();
        };
    }, [location.pathname]); // Runs when the URL changes

    return null; // This hook does not return any JSX
}

export default useTallyEmbed;
