import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Progress from '../components/pages/tutorial_page/Progress.js';
import ProgressAccordion from '../components/pages/tutorial_page/ProgressAccordion.js';
import Error from './Error.js';
import Loading from './Loading.js';

import GetData from "../hooks/getData.js";
import useIsMobile from "../hooks/useIsMobile.js";
import useTallyEmbed from "../hooks/useTallyEmbed.js";

import './Course.css';
import HighlightCode from "../HighlightCode.js";

const parse = require('html-react-parser');
const DOMPurify = require('dompurify');

function Course() {
    // set up variables
    const { state } = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let level_number = params.levelNumber;
    let step_number = params.stepNumber
    let step_index = step_number - 1;
    let course_type = params.course;

    var error = false;

    let { all_steps, all_titles, loading } = GetData(state, level_number, course_type);

    if (loading === false && all_steps.length === 0) {
        error = true;
    }

    let mobile = useIsMobile();

    useTallyEmbed();

    // get current title and steps (returned as array so can use index to access)
    let current_title = all_titles[step_index];
    let current_step_content = all_steps[step_index];
    let clean_current_title = DOMPurify.sanitize(current_title)
    let clean_step_content = DOMPurify.sanitize(current_step_content, { ADD_TAGS: ["iframe"], ADD_ATTR: ['target', 'allow', 'allowfullscreen', 'frameborder', 'scrolling'] });

    useEffect(() => {
        setTimeout(() => {
            HighlightCode();
        }, 100); // Small delay to ensure DOM updates first
    }, [step_index, clean_current_title]);

    // sort out buttons - next page or not?
    let next_page = step_index + 2;
    let has_next_page = (next_page <= all_titles.length);
    let button_info = []
    if (has_next_page) {
        button_info.push(`/${course_type}/level/${level_number}/step/${next_page}`);
        button_info.push("Next");
    } else {
        button_info.push(`/${course_type}/level/${level_number}/finish`);
        button_info.push("Finish!");
    }

    if (loading) {
        return <Loading />
    } else if (error) {
        return <Error />
    } else {
        return (
            <div>
                <Helmet>
                    <title>{course_type === "python" ? "Python" : "Python Legacy"} Level {level_number} | Mission Encodeable</title>
                </Helmet>

                <div className="Header-box">
                    <h1 className="Header-title darkgreen">{course_type === "python" ? "Python" : "Python Legacy"} Level {level_number}</h1>
                </div>
                <div className="course_content">
                    {mobile ? <ProgressAccordion all_titles={all_titles} current_step={step_index + 1} /> : <Progress progress_titles={all_titles} current_step={step_index + 1} />}
                    <div className="shadow_box">
                        <h2>{clean_current_title}</h2>
                        {parse(`${clean_step_content}`)}
                        <button onClick={() => { navigate(`${button_info[0]}`, { state: { courseSteps: all_steps, courseTitles: all_titles } }); }} className="btn_orange_fill">{button_info[1]}</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Course;